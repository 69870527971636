import makeStyles from '@material-ui/core/styles/makeStyles';

const useAxiosErrorsStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'baseline',
        display: 'flex',
        marginBottom: theme.spacing(1),
        width: '100%',
        '& > h5': {
            ...theme.typography.meta,
            color: theme.palette.error.main,
            fontFamily: theme.typography.fontFamilyPoppins,
            fontWeight: theme.typography.fontWeightBold,
            marginRight: theme.spacing(0.5),
        },
        '& p': {
            ...theme.typography.meta,
            alignSelf: 'center',
            color: theme.palette.error.main,
            marginBottom: theme.spacing(0.5),
            marginTop: 0,
        },
    },
}));

export default useAxiosErrorsStyles;
