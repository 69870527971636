/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */

/* This code is complimentary to the env.sh script which places
system environment variables into the global window object's
_env_ variable, basically: 
Use environment variables declared in the local .env file (as
provided courtesy of the dotenv package) but overwrite their
values if the same variables are declared as system environment
variables
*/

export const env =
    process.env.NODE_ENV === 'development' || window._env_ === undefined
        ? process.env
        : window._env_;

// usage example:
// import {env} from './relative/path/to/config';
// const baseURL = `${env.REACT_APP_API_URL}`
