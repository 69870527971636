import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// STYLES
import useSubStyles from '../../theme/header/sub.style';

// FUNCTIONS
import { env } from '../../config';

const Sub = (props) => {
    const { time } = props;
    const classes = useSubStyles();

    /**
     * Formats the time left in a user's current browser session.
     *
     * @param {Number} seconds Seconds left in current user session
     * @return {String} Formatted time as `minutes:seconds`
     */
    const formatTimer = (seconds) => {
        const floor = Math.floor(seconds);
        return `${Math.floor(floor / 60)}:${floor % 60}`;
    };

    /**
     * Checks if user has less than 10 minutes left in current browser session.
     *
     * @param {Number} seconds Seconds left in current user session
     * @return {Boolean} Whether to highlight timer and trigger aria alert
     */
    const highlightTimer = (seconds) => {
        const tenMins = 600;
        const floor = Math.floor(seconds);
        return floor < tenMins;
    };

    return (
        <Toolbar className={classes.toolbar} disableGutters variant="dense">
            <Container
                className={cx(
                    classes.container,
                    time || env.REACT_APP_DEV_WATERMARK === 'true' ? '' : classes.alignRight
                )}
            >
                {time && (
                    <Box
                        alignItems="center"
                        aria-live="assertive"
                        className={cx(
                            classes.timer,
                            highlightTimer(time) ? classes.timerHighlight : ''
                        )}
                        color="grey.30"
                        display="flex"
                        justifyContent="left"
                        role="alert"
                    >
                        <Typography variant="srOnly">
                            {highlightTimer(time) && (
                                <span>
                                    Less than ten minutes remaining in browser session, please
                                    navigate to a different page to restart the timer. If you are
                                    editing a form, remember to save any unsaved content.
                                </span>
                            )}
                        </Typography>
                        <Typography className={classes.text} component="span" variant="body1">
                            <strong>SESSION TIME</strong>
                        </Typography>
                        <Typography className={classes.text} component="span" variant="body1">
                            {formatTimer(time)}
                        </Typography>
                    </Box>
                )}
                {env.REACT_APP_DEV_WATERMARK === 'true' && (
                    <Box
                        alignItems="center"
                        aria-live="assertive"
                        color="styleguide.main"
                        display="flex"
                        justifyContent="left"
                        role="alert"
                    >
                        <Typography className={classes.text} component="span" variant="body1">
                            <strong>THIS IS NOT PRODUCTION &#x1F6E0; </strong>
                        </Typography>
                    </Box>
                )}
                <Box
                    alignItems="center"
                    color="grey.30"
                    display="flex"
                    justifyContent="right"
                    textAlign="right"
                >
                    <Typography
                        className={cx(classes.text, classes.poppins)}
                        component="span"
                        variant="body1"
                    >
                        <strong>
                            <Hidden smDown>WELCOME TO THE</Hidden> AUSTRALIAN SYNCHROTRON
                        </strong>
                    </Typography>
                </Box>
            </Container>
        </Toolbar>
    );
};

Sub.propTypes = {
    // Seconds left in user's browser session
    time: PropTypes.number,
};

Sub.defaultProps = {
    time: null,
};

export default Sub;
