import { hexToRgb, lighten } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useFaqPanelStyles = makeStyles((theme) => ({
    panel: {
        boxShadow: 'none',
        border: `1px solid ${theme.palette.background.light}`,
        '&::before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 0,
        },
        'h2 + &': {
            boxShadow: theme.props.shadows.tableTop,
        },
        '& + &': {
            borderTop: 'none',
            boxShadow: theme.props.shadows.tableBottom,
        },
    },
    summary: {
        minHeight: theme.spacing(7),
        padding: theme.spacing(2),
        transition: `background-color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeIn}, padding ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        },
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
        },
        '&.Mui-expanded': {
            minHeight: theme.spacing(7),
        },
        '&:focus, &:hover, &[aria-expanded=true], &[aria-expanded=true] + div': {
            backgroundColor: lighten(hexToRgb(theme.palette.common.blue), 0.95),
        },
        '& h4': {
            transition: `${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeIn}`,
        },
        '&:focus h4, &:hover h4': {
            color: theme.palette.action.hover,
        },
        '& $expandIcon': {
            transition: `${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeIn}`,
        },
        '&:focus $expandIcon, &:hover $expandIcon': {
            color: theme.palette.action.hover,
        },
        '&:focus $anchorIcon, &:hover $anchorIcon, &[aria-expanded=true] $anchorIcon': {
            opacity: 1,
        },
        '& > div': {
            margin: 0,
        },
        '& > div.Mui-expanded': {
            margin: 0,
        },
    },
    expandIcon: {
        padding: 0,
        [theme.breakpoints.up('lg')]: {
            height: 28,
        },
    },
    anchorIcon: {
        opacity: 0,
        padding: `0 ${theme.spacing(0.5)}px`,
        transition: `color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeIn}, opacity ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeIn}`,
        '&:hover, &:focus': {
            color: theme.palette.secondary.main,
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.up('lg')]: {
            height: theme.spacing(3.5),
        },
    },
    tooltip: {
        ...theme.typography.meta,
        backgroundColor: theme.palette.gradient.dark,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        fontFamily: theme.typography.fontFamilyPoppins,
        maxWidth: 'none',
    },
    tooltipArrow: {
        color: theme.palette.gradient.dark,
    },
    details: {
        ...theme.props.richtext,
        padding: `0 ${theme.spacing(2)}px`,
        transition: `padding ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
        [theme.breakpoints.up('sm')]: {
            padding: `0 ${theme.spacing(3)}px ${theme.spacing(1)}px`,
        },
        [theme.breakpoints.up('md')]: {
            padding: `0 ${theme.spacing(4)}px ${theme.spacing(1.75)}px`,
        },
    },
}));

export default useFaqPanelStyles;
