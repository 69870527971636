import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// COMPONENTS
import ButtonIcon from '../elements/ButtonIcon';
import MessageBox from '../elements/MessageBox';
import DatasetRow from './DatasetRow';
import DatasetSearch from './DatasetSearch';
import DatasetSort from './DatasetSort';

// SVGS
import SvgTune from '../svgs/Tune';

// FUNCTIONS
import { formatDate, getComparator, stableSort } from '../functions/utils';

// STYLES
import useDatasetTableStyles from '../../theme/main/datasetTable.style';

const DatasetTable = (props) => {
    const { dataset } = props;
    const classes = useDatasetTableStyles();

    const [displayed, setDisplayed] = React.useState(true);
    const [orderBy, setOrderBy] = React.useState('asc');
    const [sortBy, setSortBy] = React.useState('epn');
    const [sortingOpen, setSortingOpen] = React.useState(true);
    const [searching, setSearching] = React.useState('');
    const [total, setTotal] = React.useState(dataset.length);
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 15;

    const matchSearch = (row, input) => {
        const str = input.toLowerCase();
        if (
            row.epn.toLowerCase().includes(str) ||
            row.beamline.toLowerCase().includes(str) ||
            row.title.toLowerCase().includes(str) ||
            formatDate(row.expires_on).toLowerCase().includes(str) ||
            formatDate(row.start_date).toLowerCase().includes(str) ||
            formatDate(row.end_date).toLowerCase().includes(str) ||
            row.pi.toLowerCase().includes(str) ||
            row.owner.toLowerCase().includes(str)
        )
            return row;
        return false;
    };

    const calculateTotal = (args) => {
        const { input, checkbox } = args;
        const count = dataset
            .filter((data) => {
                if (checkbox) return data;
                return data.storage_state !== 'deleted' ? data : false;
            })
            .filter((data) => {
                if (!input) return data;
                return matchSearch(data, input);
            }).length;
        setTotal(count);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleRequestDisplayed = (value) => {
        if (page !== 0) setPage(0);
        calculateTotal({ input: searching, checkbox: value });
        setDisplayed(value);
    };

    const handleRequestSort = (value) =>
        value === 'asc' || value === 'desc' ? setOrderBy(value) : setSortBy(value);

    const handleRequestSearch = (input) => {
        if (page !== 0) setPage(0);
        calculateTotal({ input, checkbox: displayed });
        setSearching(input);
    };

    const handleSortingToggle = () => {
        setSortingOpen(!sortingOpen);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ButtonIcon
                        aria-controls="sorting-options-menu"
                        aria-expanded={sortingOpen}
                        aria-label="Toggle Sorting Options Menu"
                        customClasses={classes.buttonSort}
                        onClick={handleSortingToggle}
                        title="Toggle Sorting Options Menu"
                    >
                        <SvgTune role="presentation" />
                    </ButtonIcon>
                </Grid>
                <Grid item xs={6}>
                    <DatasetSearch onRequestSearch={handleRequestSearch} total={total} />
                </Grid>
            </Grid>
            <Collapse in={sortingOpen} timeout="auto" unmountOnExit id="sorting-options-menu">
                <DatasetSort
                    onRequestSort={handleRequestSort}
                    onRequestDisplayed={handleRequestDisplayed}
                />
            </Collapse>

            <div className={classes.tableContainer}>
                {total > 0 && (
                    <Table aria-label="Data Records Table">
                        <Hidden smDown>
                            <TableHead>
                                <TableRow className={classes.row}>
                                    <TableCell className={classes.cell}>
                                        <Typography variant="srOnly">Storage State</Typography>
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <Typography variant="overline">EPN</Typography>
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <Typography variant="overline">Beamline</Typography>
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <Typography variant="overline">Title</Typography>
                                    </TableCell>
                                    <TableCell className={classes.cell} align="right">
                                        <Typography variant="overline">Size</Typography>
                                    </TableCell>
                                    <TableCell className={classes.cell} align="right">
                                        <Typography variant="overline">Expiry Date</Typography>
                                    </TableCell>
                                    <TableCell className={classes.cell} />
                                </TableRow>
                            </TableHead>
                        </Hidden>
                        <TableBody className={classes.body}>
                            {stableSort(dataset, getComparator(orderBy, sortBy))
                                .filter((data) => {
                                    if (displayed) return data;
                                    return data.storage_state !== 'deleted' ? data : false;
                                })
                                .filter((data) => {
                                    if (!searching) return data;
                                    return matchSearch(data, searching);
                                })
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((data) => (
                                    <DatasetRow key={data.epn} row={data} />
                                ))}
                        </TableBody>
                    </Table>
                )}
                {total > 0 && (
                    <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10]}
                        backIconButtonProps={{
                            disableRipple: true,
                            color: 'primary',
                            className: classes.paginationButton,
                        }}
                        nextIconButtonProps={{
                            disableRipple: true,
                            color: 'primary',
                            className: classes.paginationButton,
                        }}
                    />
                )}
                {total < 1 && (
                    <MessageBox>
                        <p>No matching data records found.</p>
                    </MessageBox>
                )}
            </div>
        </>
    );
};

DatasetTable.propTypes = {
    dataset: PropTypes.instanceOf(Array),
};

DatasetTable.defaultProps = {
    dataset: [],
};

export default DatasetTable;
