import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgTune = (props) => (
    <SvgIcon viewBox="0 0 32 32" {...props}>
        <path d="M22.667 12H20V4h2.667v2.667H28v2.666h-5.333V12zM4 9.333V6.667h13.333v2.666H4zm5.333 5.334V12H12v8H9.333v-2.667H4v-2.666h5.333zM28 17.333v-2.666H14.667v2.666H28zM17.333 28v-2.667H28v-2.666H17.333V20h-2.666v8h2.666zM4 25.333v-2.666h8v2.666H4z" />
    </SvgIcon>
);

export default SvgTune;
