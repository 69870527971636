import makeStyles from '@material-ui/core/styles/makeStyles';

const useDatasetRowStyles = makeStyles((theme) => ({
    dataExpiring: {
        backgroundColor: theme.palette.warning.light,
    },
    dataExpired: {
        backgroundColor: theme.palette.error.light,
    },
    dataDeleted: {
        backgroundColor: theme.palette.action.disabledBackground,
        '& svg': {
            color: theme.palette.action.disabled,
        },
    },
    statusIcon: {
        alignItems: 'center',
        borderRadius: theme.shape.borderCircle,
        display: 'flex',
        height: theme.spacing(3),
        justifyContent: 'center',
        width: theme.spacing(3),
        '& > svg': {
            fill: theme.palette.common.white,
            height: theme.spacing(2),
            width: theme.spacing(2),
        },
    },
    statusRed: {
        backgroundColor: theme.palette.error.main,
    },
    statusGrey: {
        backgroundColor: theme.palette.common.grey,
    },
    statusYellow: {
        backgroundColor: theme.palette.common.yellow,
    },
    label: {
        borderBottom: 'none',
        color: theme.palette.text.secondary,
        lineHeight: 1,
        textTransform: 'uppercase',
    },
    labelDetails: {
        marginBottom: theme.spacing(1),
    },
    labelMobileOnly: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
            visibility: 'hidden',
        },
    },
    field: {
        color: theme.palette.common.black,
        borderBottom: 'none',
    },
    toggle: {
        ...theme.props.iconButton,
        padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px ${theme.spacing(
            2
        )}px ${theme.spacing(1.5)}px`,
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1),
        },
    },
    mainRow: {
        borderLeft: `1px solid ${theme.palette.background.light}`,
        borderRight: `1px solid ${theme.palette.background.light}`,
        borderTop: `1px solid ${theme.palette.background.light}`,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexWrap: 'wrap',
            verticalAlign: 'top',
            width: '100%',
        },
    },
    cell: {
        padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        verticalAlign: 'text-bottom',
    },
    cellStorageState: {
        minWidth: theme.spacing(3),
        padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        },
    },
    cellEpn: {
        [theme.breakpoints.down('sm')]: {
            minWidth: theme.spacing(8.5),
            width: theme.spacing(8.5),
            padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
        },
    },
    cellBeamline: {
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${theme.spacing(39)}px)`,
        },
    },
    cellSize: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            minWidth: theme.spacing(11),
            width: theme.spacing(11),
        },
    },
    cellExpiryDate: {
        [theme.breakpoints.down('sm')]: {
            minWidth: theme.spacing(11),
            width: theme.spacing(11),
        },
    },
    cellToggle: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1),
        },
    },
    cellTitle: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(3),
            order: 1,
            paddingTop: 0,
            marginTop: `-${theme.spacing(1)}px`,
            width: `calc(100% - ${theme.spacing(8)}px)`,
        },
    },
    detailsWrapper: {
        borderBottom: 'none',
        borderLeft: `1px solid ${theme.palette.background.light}`,
        borderRight: `1px solid ${theme.palette.background.light}`,
        padding: `0 ${theme.spacing(5)}px 0 ${theme.spacing(3)}px`,
        [theme.breakpoints.up('md')]: {
            padding: `0 ${theme.spacing(7)}px 0 ${theme.spacing(5.5)}px`,
        },
    },
    detailsRow: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    detailsCell: {
        padding: `0 0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
        [theme.breakpoints.up('md')]: {
            padding: `0 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        },
    },
    detailsCellStartDate: {
        minWidth: '50%',
        width: '50%',
    },
    detailsCellPi: {
        minWidth: '50%',
        textAlign: 'right',
        width: '50%',
    },
    detailsCellEndDate: {
        minWidth: '50%',
        paddingBottom: theme.spacing(2),
        width: '50%',
    },
    detailsCellOwner: {
        minWidth: '50%',
        paddingBottom: theme.spacing(2),
        textAlign: 'right',
        width: '50%',
    },
}));

export default useDatasetRowStyles;
