import React, { useCallback, useEffect, useState } from 'react';

// MUI COMPONENTS
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// COMPONENTS
import Announcement from '../components/elements/Announcement';
import AxiosErrors from '../components/elements/AxiosErrors';
import Breadcrumbs from '../components/elements/Breadcrumbs';
import LoadingIcon from '../components/elements/LoadingIcon';
import MessageBox from '../components/elements/MessageBox';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import DatasetTable from '../components/main/DatasetTable';
import SideMenu from '../components/modules/SideMenu';

// FUNCTIONS
import useAxios from '../components/functions/useAxios';
import { formatBFFPostPutErrors } from '../components/functions/utils';
import { env } from '../config';

// STYLES
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    sidebar: {
        '&:first-child': {
            marginTop: theme.spacing(5),
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(2.75 + 4), // Breadcrumb height + Breadcrumb bottom margin
            },
        },
    },
    error: {
        marginBottom: theme.spacing(5),
    },
}));

export default () => {
    const classes = useStyles();
    const [errMsgs, setErrMsgs] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const bffAxios = useAxios(`${env.REACT_APP_DMPUBLIC_BFF_URL}`);
    const [user, setUser] = useState({});
    const [datasets, setDatasets] = useState([]);

    const getUser = useCallback(async () => {
        if (bffAxios) {
            bffAxios
                .get(`/user/current`)
                .then((res) => {
                    setUser(res.data);
                    setLoaded(true);
                })
                .catch((error) => {
                    const errs = formatBFFPostPutErrors(error);
                    setErrMsgs(errs);
                });
        }
    }, [bffAxios]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    const getDatasets = useCallback(async () => {
        if (bffAxios) {
            bffAxios
                .get(`/datasets`)
                .then((res) => {
                    setDatasets(res.data.datasets);
                    setLoaded(true);
                })
                .catch((error) => {
                    const errs = formatBFFPostPutErrors(error);
                    setErrMsgs(errs);
                });
        }
    }, [bffAxios]);

    useEffect(() => {
        getDatasets();
    }, [getDatasets]);

    return (
        <>
            <a
                href="#skip-to-main"
                className="skip-to-main-link"
                aria-label="skip to main content"
            >
                Skip to main content
            </a>
            <Header user={user} title="Data Status" />
            <Container id="skip-to-main">
                <Announcement />
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid className={classes.main} component="main" item md={8} xs={12}>
                        <Breadcrumbs
                            crumbs={[
                                {
                                    label: 'Dashboard',
                                    link: env.REACT_APP_DASHBOARD_USER_UI_URL,
                                },
                            ]}
                            current="Data Status"
                        />
                        {errMsgs.length > 0 && (
                            <AxiosErrors
                                errors={errMsgs}
                                renderType="page"
                                customClasses={classes.section}
                            />
                        )}
                        {loaded && datasets.length > 0 && <DatasetTable dataset={datasets} />}
                        {loaded && (!datasets.length || datasets.length < 1) && (
                            <MessageBox>
                                <p>You do not have any data records.</p>
                                <p>
                                    If this does not seem accurate, please contact the Australian
                                    Synchrotron Scientific Computing team at{' '}
                                    <a href="mailto:as-scientific-computing@ansto.gov.au">
                                        <span>as-scientific-computing@ansto.gov.au</span>
                                    </a>{' '}
                                    for assistance.
                                </p>
                            </MessageBox>
                        )}
                        {!loaded && (
                            <Container>
                                <LoadingIcon />
                            </Container>
                        )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SideMenu customClasses={classes.sidebar} />
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
};
