// eslint-disable-next-line import/prefer-default-export
export const addModuleButtons = (themeInput) => {
    const theme = { ...themeInput };

    theme.props.buttonIconOnly = {
        color: theme.palette.common.white,
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            backgroundColor: 'transparent',
        },
        '&:hover $toggleIcon .transition--mid': {
            opacity: 1,
            transition: `opacity ${theme.transitions.duration.shorter}ms`,
        },
        '&:hover $toggleIcon .transition--full': {
            opacity: 1,
            transition: `opacity ${theme.transitions.duration.standard}ms`,
        },
        '&:focus $toggleIcon .transition--mid': {
            opacity: 1,
            transition: `opacity ${theme.transitions.duration.shorter}ms`,
        },
        '&:focus $toggleIcon .transition--full': {
            opacity: 1,
            transition: `opacity ${theme.transitions.duration.standard}ms`,
        },
        '& $toggleIcon .transition--mid': {
            opacity: 0,
            transition: `opacity ${theme.transitions.duration.standard}ms`,
        },
        '& $toggleIcon .transition--full': {
            opacity: 0,
            transition: `opacity ${theme.transitions.duration.shorter}ms`,
        },
    };

    return theme;
};
