import makeStyles from '@material-ui/core/styles/makeStyles';

const useSubStyles = makeStyles((theme) => ({
    toolbar: {
        borderTop: `1px solid ${theme.palette.grey[10]}`,
        minHeight: 'auto',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        transition: `padding ${theme.transitions.duration.standard}ms ease-out`,
    },
    container: {
        alignItems: 'flex-start',
        display: 'flex',
        justifyContent: 'space-between',
    },
    alignRight: {
        '&$container': {
            justifyContent: 'flex-end',
        },
    },
    text: {
        ...theme.typography.headerSub,
    },
    timer: {
        '& span + span': {
            marginLeft: theme.spacing(0.5),
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    timerHighlight: {
        color: theme.palette.secondary.main,
    },
    poppins: {
        fontFamily: theme.typography.fontFamilyPoppins,
    },
}));

export default useSubStyles;
