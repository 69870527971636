import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// MUI COMPONENTS
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// COMPONENTS
import Menu from './Menu';

// STYLES
import useNavStyles from '../../theme/header/nav.style';

const Nav = (props) => {
    const { open } = props;
    const classes = useNavStyles();

    return (
        <nav id="site-menu" className={cx(classes.overlay, { active: open })}>
            <Container>
                <Grid className={classes.overlayGrid} container spacing={2}>
                    <Grid
                        className={cx(classes.drawerWrap, { active: open })}
                        item
                        xs={12}
                        sm={7}
                        md={5}
                        lg={4}
                    >
                        <div className={classes.drawer}>
                            <Menu />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </nav>
    );
};

Nav.propTypes = {
    // Whether the nav is open
    open: PropTypes.bool,
};

Nav.defaultProps = {
    open: false,
};

export default Nav;
