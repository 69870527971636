import React from 'react';

// MUI COMPONENTS
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// STYLES
import useAnnouncementStyles from '../../theme/elements/announcement.style';

const Announcement = () => {
    const classes = useAnnouncementStyles();

    const announcementExpired = () => {
        const today = new Date();
        const expiry = new Date();
        // This is June 29th, JS counts month from 0
        expiry.setFullYear(2022, 5, 29);
        if (expiry > today) return true;
        return false;
    };

    return (
        <>
            {announcementExpired() && (
                <div className={classes.announcement}>
                    <Container>
                        <Typography
                            align="center"
                            className={classes.content}
                            component="p"
                            variant="body2"
                        >
                            From June 29th 2020, all data past its Policy Expiry Date will be
                            deleted from our servers.
                        </Typography>
                    </Container>
                </div>
            )}
        </>
    );
};

export default Announcement;
