import red from '@material-ui/core/colors/red';
import { createTheme } from '@material-ui/core/styles';
import { alpha, fade, hexToRgb, lighten } from '@material-ui/core/styles/colorManipulator';

// CUSTOM STYLES
import brand from './brand';
import { addModuleBox } from './elements/box';
import { addModuleButtons } from './elements/buttons';
import { addModuleRichtext } from './elements/richtext';
import { addCustomTypography } from './typography';

// Styleguide Pages Variables
const styleguide = {
    palette: {
        main: '#ff3366',
    },
};

// Initialise Material UI Theme
// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
    palette: {
        common: {
            white: brand.palette.white,
            black: brand.palette.black,
            grey: brand.palette.grey,
            flag: brand.palette.flag,
            blue: brand.palette.blue,
            seafoam: brand.palette.seafoam,
            yellow: brand.palette.yellow,
            purple: brand.palette.purple,
            green: brand.palette.green,
        },
        gradient: {
            dark: brand.palette.flag,
            medium: brand.palette.blue,
            light: brand.palette.seafoam,
        },
        styleguide: {
            main: styleguide.palette.main,
        },
        primary: {
            main: brand.palette.blue,
        },
        secondary: {
            main: brand.palette.seafoam,
        },
        warning: {
            light: lighten(hexToRgb(brand.palette.yellow), 0.85),
            main: brand.palette.yellow,
            dark: hexToRgb('#e88e04'),
        },
        error: {
            light: lighten(red[800], 0.85),
            main: red[600],
            dark: red[900],
        },
        white: {
            60: alpha(brand.palette.white, 0.6),
            90: alpha(brand.palette.white, 0.9),
        },
        grey: {
            10: lighten(hexToRgb(brand.palette.grey), 0.9),
            30: lighten(hexToRgb(brand.palette.grey), 0.7),
        },
        text: {
            primary: brand.palette.text,
            secondary: brand.palette.grey,
        },
        background: {
            default: lighten(hexToRgb(brand.palette.grey), 0.999),
            light: lighten(hexToRgb(brand.palette.blue), 0.9),
            avatar: lighten(hexToRgb(brand.palette.seafoam), 0.8),
        },
        action: {
            active: hexToRgb(brand.palette.blue),
            hover: hexToRgb(brand.palette.seafoam),
            hoverOpacity: 0.4,
            focus: hexToRgb(brand.palette.seafoam),
            focusOpacity: 0.4,
            disabled: lighten(hexToRgb(brand.palette.grey), 0.3),
            disabledBackground: lighten(hexToRgb(brand.palette.grey), 0.9),
            disabledOpacity: 0.7,
        },
    },
    typography: {
        fontFamily: brand.fontFamily.firaSans,
        fontFamilyPoppins: brand.fontFamily.poppins,
        fontWeightExtraBold: 800,
    },
    transitions: {
        duration: {
            shortest: 80,
            shorter: 160,
            short: 240,
            standard: 320,
            complex: 400,
        },
    },
    shape: {
        borderRadius: 1,
        borderRound: 10000,
        borderCircle: '50%',
    },
});

// dm-public-ui legacy styles
theme.props.iconButton = {
    color: theme.palette.action.active,
    transition: `color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
    '&:focus': {
        backgroundColor: 'transparent',
        color: theme.palette.action.focus,
    },
    '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.action.hover,
    },
};

// Custom shadows
theme.props.shadows = {
    announcement: `inset 0 -3px 4px -1px ${fade(brand.palette.black, 0.2)}`,
    header: `0 -2px 4px 0 ${theme.palette.grey[30]}`,
    button: `0 1px 1px 0 ${theme.palette.grey[30]}, 0 0 2px 0 ${theme.palette.grey[30]}`,
    buttonHover: `0 1px 1px 0 ${theme.palette.grey[30]}, 0 0 2px 0 ${theme.palette.grey[30]}, 0 0 2px 0 ${theme.palette.grey[30]}`,
    box: `0 0 4px 0 ${alpha(brand.palette.blue, 0.2)}`,
    boxActive: `0 0 4px 0 ${alpha(brand.palette.blue, 0.7)}`,
    menu: `0 0 2px 0 ${alpha(brand.palette.blue, 0.2)}`,
    tableTop: `0 -1px 4px -1px ${alpha(brand.palette.black, 0.05)}`,
    tableBottom: `0 3px 4px -1px ${alpha(brand.palette.black, 0.05)}`,
    message: `0 3px 4px -1px ${alpha(brand.palette.black, 0.1)}`,
};

// Nav Logo responsive height
theme.props.logo = {
    height: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
        height: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
        height: theme.spacing(9),
    },
};

// Custom responsive typography
theme = addCustomTypography(theme);

// Module styles
theme = addModuleRichtext(theme);
theme = addModuleButtons(theme);
theme = addModuleBox(theme);

export default theme;
