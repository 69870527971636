import makeStyles from '@material-ui/core/styles/makeStyles';

const useDatasetSortStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        position: 'relative',
    },
    grid: {
        '&.MuiGrid-spacing-xs-2': {
            [theme.breakpoints.up('md')]: {
                margin: `-${theme.spacing(3) / 2}px`,
                width: `calc(100% + ${theme.spacing(3)}px)`,
            },
        },
        '&.MuiGrid-spacing-xs-2 > .MuiGrid-item': {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3) / 2,
            },
        },
    },
    columnRight: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
    },
    formControl: {
        '&.MuiFormControl-root': {
            display: 'flex',
        },
        '& + &': {
            marginTop: theme.spacing(2),
        },
    },
    formLabel: {
        '&.MuiFormLabel-root': {
            ...theme.typography.headerSub,
            color: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamilyPoppins,
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: theme.spacing(1),
            transition: `color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeIn}`,
        },
        '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.action.focus,
        },
    },
    formControlLabel: {
        '&.MuiFormControlLabel-labelPlacementStart': {
            justifyContent: 'space-between',
            marginLeft: 0,
            marginRight: 0,
        },
        '& .MuiFormControlLabel-label': {
            ...theme.typography.body1,
        },
        '& + &': {
            marginTop: theme.spacing(0.5),
        },
        '&:hover > $radio.MuiIconButton-root, &:focus > $radio.MuiIconButton-root, &:hover > $radio.MuiIconButton-root.Mui-checked, &:focus > $radio.MuiIconButton-root.Mui-checked':
            {
                color: theme.palette.action.hover,
                backgroundColor: 'transparent',
            },
    },
    formCheckboxLabel: {
        '& .MuiFormControlLabel-label': {
            ...theme.typography.label,
            color: theme.palette.text.secondary,
        },
    },
    radio: {
        '&.MuiIconButton-root': {
            ...theme.props.iconButton,
            padding: 0,
            '&.Mui-checked': {
                color: theme.palette.action.active,
                backgroundColor: 'transparent',
            },
        },
        '& input:focus ~ .MuiSvgIcon-root, & input:focus ~ * .MuiSvgIcon-root': {
            color: theme.palette.action.focus,
        },
        '& .MuiSvgIcon-root': {
            transition: `color ${theme.transitions.duration.shorter}ms ease-out`,
        },
    },
}));

export default useDatasetSortStyles;
