import React from 'react';

// MUI COMPONENTS
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

// FUNCTIONS
import { env } from '../../config';

// STYLES
import useFooterStyles from '../../theme/footer/footer.style';

const Footer = () => {
    const classes = useFooterStyles();
    const links = {
        privacy: 'https://www.ansto.gov.au/privacy-policy',
        terms: `${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/site/termsandconditions`,
        ansto: 'https://www.ansto.gov.au/research/facilities/australian-synchrotron/overview',
    };

    return (
        <footer className={classes.footer}>
            <Container className={classes.container}>
                <Box alignItems="center" display="flex" justifyContent="right">
                    <Link href={links.privacy} underline="none" className={classes.link}>
                        <span className={classes.linkText}>Privacy Policy</span>
                    </Link>
                    <span className={classes.separator} />
                    <Link href={links.terms} underline="none" className={classes.link}>
                        <span className={classes.linkText}>Terms of Use</span>
                    </Link>
                    <span className={classes.separator} />
                    <Link href={links.ansto} underline="none" className={classes.link}>
                        <span>
                            {'\u00A9'} {new Date().getFullYear()}{' '}
                        </span>
                        <span className={classes.linkText}>ANSTO</span>
                    </Link>
                </Box>
            </Container>
            <Container aria-hidden="true" role="presentation">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7} lg={8} className={classes.triangle} />
                </Grid>
            </Container>
        </footer>
    );
};

export default Footer;
