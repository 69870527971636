import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// MUI COMPONENTS
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

// SVGS
import SvgArrowGradient from '../svgs/ArrowGradient';

// STYLES
import useSideMenuStyles from '../../theme/modules/sidemenu.style';

const SideMenu = (props) => {
    const { customClasses } = props;
    const classes = useSideMenuStyles();
    const location = useLocation();
    const [open, setOpen] = React.useState(false);

    const toggleSideMenu = () => {
        setOpen(!open);
    };

    return (
        <aside className={cx(customClasses, classes.root)}>
            <nav
                className={cx(classes.menu, open ? classes.menuOpened : '')}
                aria-labelledby="data-status-menu"
            >
                <h2 className={classes.label} id="data-status-menu">
                    Data Status
                </h2>
                <IconButton
                    aria-label={`Toggle data status side menu to ${open ? 'close' : 'open'}`}
                    className={cx(classes.toggle, open ? classes.toggleOpened : '')}
                    disableFocusRipple
                    disableRipple
                    onClick={() => toggleSideMenu()}
                    title={`Toggle data status side menu to ${open ? 'close' : 'open'}`}
                    aria-controls="data-status-menu"
                    aria-expanded={open}
                >
                    <SvgArrowGradient className={classes.toggleIcon} role="presentation" />
                </IconButton>
                <ul className={classes.list}>
                    <li className={classes.item}>
                        <Link
                            to="/"
                            component={RouterLink}
                            aria-current={location.pathname === '/' ? 'page' : false}
                            className={cx(classes.link, {
                                active: location.pathname === '/',
                            })}
                        >
                            <span className={classes.span}>Records</span>
                        </Link>
                    </li>
                    <li className={classes.item}>
                        <Link
                            to="/faq"
                            component={RouterLink}
                            aria-current={location.pathname === '/faq' ? 'page' : false}
                            className={cx(classes.link, {
                                active: location.pathname === '/faq',
                            })}
                        >
                            <span className={classes.span}>FAQs</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};

SideMenu.propTypes = {
    customClasses: PropTypes.string,
};

SideMenu.defaultProps = {
    customClasses: null,
};

export default SideMenu;
