import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// SVGS
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';
import LinkSharpIcon from '@material-ui/icons/LinkSharp';

// COMPONENTS
import ButtonIcon from '../elements/ButtonIcon';

// STYLES
import useFaqPanelStyles from '../../theme/faqs/faqPanel.style';

const FaqPanel = (props) => {
    const { onRequestExpanded, expanded, slug, title, content } = props;
    const classes = useFaqPanelStyles();
    const [saved, setSaved] = React.useState(false);

    const createExpandHandler = (e, anchor) => {
        onRequestExpanded(e, anchor);
    };

    const copyAnchorLink = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(
            `${window.location.href.replace(window.location.hash, '')}#${slug}`
        );
        setSaved(true);

        const timer = setTimeout(() => {
            setSaved(false);
        }, 1200);
        return () => clearTimeout(timer);
    };

    return (
        <ExpansionPanel
            className={classes.panel}
            square
            expanded={expanded}
            onChange={(e) => createExpandHandler(e, slug)}
        >
            <ExpansionPanelSummary
                classes={{
                    root: classes.summary,
                    expandIcon: classes.expandIcon,
                }}
                expandIcon={<ExpandMoreSharpIcon />}
                IconButtonProps={{ disableRipple: true }}
                aria-controls={`${slug}-content`}
                id={slug}
            >
                <Typography variant="h5" color="primary">
                    {title}
                    <Tooltip
                        title={saved ? 'Copied' : 'Copy anchor link to clipboard'}
                        leaveDelay={saved ? 800 : 400}
                        classes={{
                            arrow: classes.tooltipArrow,
                            tooltip: classes.tooltip,
                        }}
                        placement="right"
                        arrow
                    >
                        <ButtonIcon
                            className={classes.anchorIcon}
                            title={`Copy anchor link to this FAQ panel: ${title}`}
                            onClick={(e) => copyAnchorLink(e)}
                            onFocus={(e) => e.stopPropagation()}
                        >
                            <LinkSharpIcon role="presentation" />
                        </ButtonIcon>
                    </Tooltip>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                <Typography variant="body2" component="div">
                    {content}
                </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

FaqPanel.propTypes = {
    onRequestExpanded: PropTypes.func,
    expanded: PropTypes.bool,
    slug: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.node,
};

FaqPanel.defaultProps = {
    onRequestExpanded: () => null,
    expanded: false,
    slug: '',
    title: '',
    content: null,
};

export default FaqPanel;
