import makeStyles from '@material-ui/core/styles/makeStyles';

const useFooterStyles = makeStyles((theme) => ({
    footer: {
        marginTop: 'auto',
    },
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(20),
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(24.25),
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(25),
        },
    },
    link: {
        ...theme.typography.footer,
        color: theme.palette.common.white,
        '&:hover $linkText': {
            color: theme.palette.common.white,
        },
        '&:hover $linkText:after': {
            width: '100%',
        },
        '&:focus $linkText': {
            color: theme.palette.common.white,
        },
        '&:focus $linkText:after': {
            width: '100%',
        },
    },
    linkText: {
        position: 'relative',
        transition: `color ${theme.transitions.duration.shorter}ms ease-out`,
        '&:after': {
            backgroundColor: theme.palette.common.white,
            bottom: -2,
            content: '""',
            display: 'block',
            height: 1,
            left: 0,
            position: 'absolute',
            transition: `width ${theme.transitions.duration.shorter}ms ease-out`,
            width: '0%',
        },
    },
    separator: {
        backgroundColor: theme.palette.white[60],
        display: 'block',
        height: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 1,
    },
    triangle: {
        position: 'relative',
        '&::after': {
            backgroundColor: theme.palette.gradient.dark,
            backgroundImage: `linear-gradient(to bottom, ${theme.palette.gradient.dark} 0%, ${theme.palette.gradient.medium} 70%, ${theme.palette.gradient.light} 100%)`,
            content: '""',
            display: 'block',
            height: '100vh',
            position: 'absolute',
            top: -212,
            right: -16,
            transform: 'rotate(-26deg)',
            transformOrigin: 'top right',
            transition: `transform ${theme.transitions.duration.complex}ms ${theme.transitions.easing.sharp}`,
            width: '200vw',
            zIndex: -1,
            [theme.breakpoints.up('md')]: {
                bottom: '-100vh',
                left: 'calc(100% - 4px)',
                right: 'auto',
                top: 'auto',
                transform: 'rotate(-26deg)',
                transformOrigin: 'top left',
            },
            [theme.breakpoints.up('lg')]: {
                transform: 'rotate(-32deg)',
            },
        },
    },
}));

export default useFooterStyles;
