import makeStyles from '@material-ui/core/styles/makeStyles';

const useAnnouncementStyles = makeStyles((theme) => ({
    announcement: {
        background: theme.palette.common.flag,
        boxShadow: theme.props.shadows.announcement,
        marginBottom: theme.spacing(1),
        padding: `${theme.spacing(1)}px 0`,
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(5),
        },
        '&:before': {
            backgroundColor: theme.palette.common.flag,
            content: '""',
            display: 'block',
            height: '100%',
            left: '-100vw',
            position: 'absolute',
            top: 0,
            width: '300vw',
            zIndex: -1,
        },
    },
    content: {
        color: theme.palette.common.white,
    },
}));

export default useAnnouncementStyles;
