import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// MUI COMPONENTS
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// COMPONENTS
import AxiosErrors from '../components/elements/AxiosErrors';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';

// SVGS
import SvgExclaimation from '../components/svgs/Exclaimation';

// FUNCTIONS
import useAxios from '../components/functions/useAxios';
import { formatBFFPostPutErrors } from '../components/functions/utils';
import { env } from '../config';

// STYLES
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        '& + &': {
            marginTop: theme.spacing(5),
        },
    },
    box: {
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(5),
        },
    },
    icon: {
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    text: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
        },
        '& button': {
            ...theme.typography.body2,
            background: 'transparent',
            border: 'none',
            boxSizing: 'unset',
            color: theme.palette.common.white,
            cursor: 'pointer',
            fontWeight: theme.typography.fontWeightRegular,
            padding: 0,
        },
        '& p:first-child': {
            marginTop: 0,
        },
        '& p': {
            wordBreak: 'break-word',
            [theme.breakpoints.up('lg')]: {
                marginBlockEnd: `${theme.spacing(3)}px`,
                marginBlockStart: `${theme.spacing(3)}px`,
            },
        },
        '& a, & button': {
            color: 'inherit',
            textDecoration: 'none',
            fontWeight: theme.typography.fontWeightRegular,
            '& span': {
                position: 'relative',
                whiteSpace: 'nowrap',
            },
            '& span:after': {
                backgroundColor: theme.palette.common.white,
                bottom: -2,
                content: '""',
                display: 'block',
                height: 1,
                left: 0,
                position: 'absolute',
                transition: `width ${theme.transitions.duration.shorter}ms ease-out`,
                width: '0%',
            },
            '&:hover > span:after': {
                width: '100%',
            },
            '&:focus > span:after': {
                width: '100%',
            },
        },
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const [errMsgs, setErrMsgs] = useState([]);
    const history = useHistory();
    const bffAxios = useAxios(`${env.REACT_APP_DMPUBLIC_BFF_URL}`);
    const [user, setUser] = useState({});

    const getUser = useCallback(async () => {
        if (bffAxios) {
            bffAxios
                .get(`/user/current`)
                .then((res) => {
                    setUser(res.data);
                })
                .catch((error) => {
                    const errs = formatBFFPostPutErrors(error);
                    setErrMsgs(errs);
                });
        }
    }, [bffAxios]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    return (
        <>
            <a
                href="#skip-to-main"
                className="skip-to-main-link"
                aria-label="skip to main content"
            >
                Skip to main content
            </a>
            <Header user={user} title="Page Not Found" />
            <Container id="skip-to-main">
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid className={classes.main} component="main" item md={8} xs={12}>
                        {errMsgs.length > 0 && (
                            <AxiosErrors
                                errors={errMsgs}
                                renderType="page"
                                customClasses={classes.section}
                            />
                        )}
                        <div>
                            <Typography className={classes.title} color="primary" variant="h2">
                                Hmm.....
                            </Typography>
                            <Box className={classes.box} color="common.white" p={2}>
                                <SvgExclaimation className={classes.icon} role="presentation" />
                                <Typography
                                    className={classes.text}
                                    variant="body2"
                                    component="div"
                                >
                                    <p>The page you&apos;re looking for doesn&apos;t exist.</p>
                                    <p>
                                        You can click your browser back button to return to the{' '}
                                        <button type="button" onClick={() => history.goBack()}>
                                            <span>previous page</span>
                                        </button>
                                        , or go to the Australian Synchrotron{' '}
                                        <a href={env.REACT_APP_DASHBOARD_USER_UI_URL}>
                                            <span>User Hub home page</span>
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        If you require further assistance, please contact the
                                        Australian Synchrotron User Office at{' '}
                                        <a href="mailto:user.office.vic@ansto.gov.au">
                                            <span>user.office.vic@ansto.gov.au</span>
                                        </a>
                                    </p>
                                </Typography>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
};

export default NotFound;
