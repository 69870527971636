import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// SVGS
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import LayersSharpIcon from '@material-ui/icons/LayersSharp';
import LockSharpIcon from '@material-ui/icons/LockSharp';
import ZoomInSharpIcon from '@material-ui/icons/ZoomInSharp';
import ZoomOutSharpIcon from '@material-ui/icons/ZoomOutSharp';

// FUNCTIONS
import { formatDate } from '../functions/utils';

// STYLES
import useDatasetRowStyles from '../../theme/main/datasetRow.style';

const DatasetRow = (props) => {
    const { row } = props;
    const classes = useDatasetRowStyles();
    const [open, setOpen] = React.useState(false);
    let storageComp = null;

    const readableBytes = (bytes) => {
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        return bytes ? `${(bytes / 1024 ** i).toFixed(2) * 1} ${sizes[i]}` : 'N/A';
    };

    const calcExpiry = (timestamp) => {
        const expiryDate = new Date(timestamp);
        const today = new Date();
        return (expiryDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24.0);
    };

    const dataExpiring = (timestamp) => {
        const warningPeriod = 31;
        if (calcExpiry(timestamp) < warningPeriod) return true;
        return false;
    };

    const dataExpired = (timestamp) => {
        if (calcExpiry(timestamp) < 0) return true;
        return false;
    };

    switch (row.storage_state.toLowerCase()) {
        case 'compressed':
            storageComp = (
                <span
                    className={cx(classes.statusIcon, classes.statusYellow)}
                    title="State: Data is compressed"
                    label="State: Data is compressed"
                >
                    <LayersSharpIcon role="presentation" />
                </span>
            );
            break;
        case 'archived':
            storageComp = (
                <span
                    className={cx(classes.statusIcon, classes.statusGrey)}
                    title="State: Data is archived"
                    label="State: Data is archived"
                >
                    <LockSharpIcon role="presentation" />
                </span>
            );
            break;
        case 'deleted':
            storageComp = (
                <span
                    className={cx(classes.statusIcon, classes.statusRed)}
                    title="State: Data is deleted"
                    label="State: Data is deleted"
                >
                    <DeleteSharpIcon role="presentation" />
                </span>
            );
            break;
        default:
            storageComp = null;
            break;
    }

    return (
        <>
            <TableRow
                className={cx(
                    classes.mainRow,
                    dataExpired(row.expires_on) ? classes.dataExpired : '',
                    dataExpiring(row.expires_on) ? classes.dataExpiring : '',
                    row.storage_state === 'deleted' ? classes.dataDeleted : ''
                )}
            >
                <TableCell className={cx(classes.field, classes.cell, classes.cellStorageState)}>
                    {storageComp}
                </TableCell>
                <TableCell
                    className={cx(classes.field, classes.cell, classes.cellEpn)}
                    scope="row"
                >
                    <Typography
                        variant="overline"
                        component="p"
                        className={cx(
                            classes.label,
                            classes.labelDetails,
                            classes.labelMobileOnly
                        )}
                    >
                        EPN
                    </Typography>
                    <Typography variant="body1">{row.epn}</Typography>
                </TableCell>
                <TableCell className={cx(classes.field, classes.cell, classes.cellBeamline)}>
                    <Typography
                        variant="overline"
                        component="p"
                        className={cx(
                            classes.label,
                            classes.labelDetails,
                            classes.labelMobileOnly
                        )}
                    >
                        Beamline
                    </Typography>
                    <Typography variant="body1">{row.beamline}</Typography>
                </TableCell>
                <TableCell className={cx(classes.field, classes.cell, classes.cellTitle)}>
                    <Typography
                        variant="overline"
                        component="p"
                        className={cx(
                            classes.label,
                            classes.labelDetails,
                            classes.labelMobileOnly
                        )}
                    >
                        Title
                    </Typography>
                    <Typography variant="body2">{row.title}</Typography>
                </TableCell>
                <TableCell
                    className={cx(classes.field, classes.cell, classes.cellSize)}
                    align="right"
                >
                    <Typography
                        variant="overline"
                        component="p"
                        className={cx(
                            classes.label,
                            classes.labelDetails,
                            classes.labelMobileOnly
                        )}
                    >
                        Size
                    </Typography>
                    <Typography variant="body2">{readableBytes(row.size)}</Typography>
                </TableCell>
                <TableCell
                    className={cx(classes.field, classes.cell, classes.cellExpiryDate)}
                    align="right"
                >
                    <Typography
                        variant="overline"
                        component="p"
                        className={cx(
                            classes.label,
                            classes.labelDetails,
                            classes.labelMobileOnly
                        )}
                    >
                        Expiry
                    </Typography>
                    <Typography variant="body1">{formatDate(row.expires_on)}</Typography>
                </TableCell>
                <TableCell className={cx(classes.field, classes.cellToggle)}>
                    <IconButton
                        className={classes.toggle}
                        aria-label={`Toggle data record details for EPN ${row.epn}`}
                        title={`Toggle data record details for EPN ${row.epn}`}
                        size="small"
                        disableRipple
                        disableFocusRipple
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ZoomOutSharpIcon /> : <ZoomInSharpIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow
                className={cx(
                    dataExpired(row.expires_on) ? classes.dataExpired : '',
                    dataExpiring(row.expires_on) ? classes.dataExpiring : '',
                    row.storage_state === 'deleted' ? classes.dataDeleted : ''
                )}
            >
                <TableCell className={classes.detailsWrapper} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table aria-label={`Data record details for EPN ${row.epn}`}>
                            <TableBody>
                                <TableRow className={classes.detailsRow}>
                                    <TableCell
                                        className={cx(
                                            classes.field,
                                            classes.detailsCell,
                                            classes.detailsCellStartDate
                                        )}
                                    >
                                        <Typography
                                            variant="overline"
                                            component="p"
                                            className={cx(classes.label, classes.labelDetails)}
                                        >
                                            Experiment Start Date
                                        </Typography>
                                        <Typography variant="body1">
                                            {formatDate(row.start_date)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        className={cx(
                                            classes.field,
                                            classes.detailsCell,
                                            classes.detailsCellPi
                                        )}
                                    >
                                        <Typography
                                            variant="overline"
                                            component="p"
                                            className={cx(classes.label, classes.labelDetails)}
                                        >
                                            Principal Investigator
                                        </Typography>
                                        <Typography variant="body1">{row.pi}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableBody>
                                <TableRow className={classes.detailsRow}>
                                    <TableCell
                                        className={cx(
                                            classes.field,
                                            classes.detailsCell,
                                            classes.detailsCellEndDate
                                        )}
                                    >
                                        <Typography
                                            variant="overline"
                                            component="p"
                                            className={cx(classes.label, classes.labelDetails)}
                                        >
                                            Experiment End Date
                                        </Typography>
                                        <Typography variant="body1">
                                            {formatDate(row.end_date)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        className={cx(
                                            classes.field,
                                            classes.detailsCell,
                                            classes.detailsCellOwner
                                        )}
                                    >
                                        <Typography
                                            variant="overline"
                                            component="p"
                                            className={cx(classes.label, classes.labelDetails)}
                                        >
                                            Owner
                                        </Typography>
                                        <Typography variant="body1">{row.owner}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

DatasetRow.propTypes = {
    row: PropTypes.instanceOf(Object),
};

DatasetRow.defaultProps = {
    row: null,
};

export default DatasetRow;
