import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// SVGS
import SvgExclaimation from '../svgs/Exclaimation';

// STYLES
import useMessageBoxStyles from '../../theme/elements/messageBox.style';

const MessageBox = (props) => {
    const { customClasses, alert, icon, children } = props;
    const classes = useMessageBoxStyles();

    return (
        <div className={cx(classes.root, customClasses, alert ? classes.alert : '')}>
            {icon !== null && <div className={classes.icon}>{icon}</div>}
            <div className={classes.richtext}>{children}</div>
        </div>
    );
};

MessageBox.propTypes = {
    alert: PropTypes.bool,
    children: PropTypes.node,
    customClasses: PropTypes.string,
    icon: PropTypes.node,
};

MessageBox.defaultProps = {
    alert: false,
    children: null,
    customClasses: null,
    icon: <SvgExclaimation />,
};

export default MessageBox;
