import cx from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

// MUI COMPONENTS
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// FUNCTIONS
import { env } from '../../config';

// STYLES
import useMenuStyles from '../../theme/header/menu.style';

const Menu = () => {
    const classes = useMenuStyles();
    const location = useLocation();
    const menuItems = [
        {
            label: false,
            children: [
                {
                    site: env.REACT_APP_DASHBOARD_USER_UI_URL,
                    slug: false,
                    name: 'Dashboard',
                },
                {
                    site: `${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/site/dashboard`,
                    slug: false,
                    name: 'User Portal',
                },
            ],
        },
        {
            label: 'Experiments',
            children: [
                {
                    site: `${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/proposal/admin`,
                    slug: false,
                    name: 'Proposals',
                },
                {
                    site: `${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/experimentalAuthorisation/admin`,
                    slug: false,
                    name: 'Experiment Authorisations',
                },
                {
                    site: 'https://asci.synchrotron.org.au/',
                    slug: false,
                    name: 'ASCI Desktop',
                },
                {
                    site: `${env.REACT_APP_DATA_STATUS_USER_UI_URL}`,
                    slug: false,
                    name: 'Data Status',
                },
            ],
        },
        {
            label: 'Travel',
            children: [
                {
                    site: env.REACT_APP_TRAVEL_GRANT_USER_UI_URL,
                    slug: false,
                    name: 'Travel Grants',
                },
                {
                    site: env.REACT_APP_ACCOM_REQUEST_USER_UI_URL,
                    slug: false,
                    name: 'Accommodation Requests',
                },
                {
                    site: env.REACT_APP_VISA_DOC_USER_UI_URL,
                    slug: false,
                    name: 'Visa Documentation',
                },
            ],
        },
        {
            label: false,
            children: [
                {
                    site: `${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/quizEngine/examExam/listforuser`,
                    slug: false,
                    name: 'Safety Inductions',
                },
                {
                    site: `${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/publication/publication/admin`,
                    slug: false,
                    name: 'Publications',
                },
            ],
        },
    ];
    const footerLinks = {
        privacy: 'https://www.ansto.gov.au/privacy-policy',
        terms: `${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/site/termsandconditions`,
        ansto: 'https://www.ansto.gov.au/research/facilities/australian-synchrotron/overview',
    };

    return (
        <>
            <ul className={classes.section}>
                {menuItems.map((data, i) => {
                    if (data.children.length > 0) {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={i.toString()}>
                                <ul className={classes.section}>
                                    {data.label && (
                                        <Typography className={classes.label} component="h5">
                                            {data.label}
                                        </Typography>
                                    )}
                                    {data.children.map((child) => (
                                        <li key={child.name.toString()}>
                                            <Link
                                                aria-current={
                                                    location.pathname === child.slug
                                                        ? 'page'
                                                        : false
                                                }
                                                className={cx(classes.link, {
                                                    active: location.pathname === child.slug,
                                                })}
                                                href={`${child.site ? child.site : ''}${
                                                    child.slug ? child.slug : ''
                                                }`}
                                                underline="none"
                                            >
                                                {child.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        );
                    }
                    return null;
                })}
            </ul>
            <Hidden mdUp>
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="left"
                    className={classes.footer}
                    component="ul"
                >
                    <Link
                        href={footerLinks.privacy}
                        underline="none"
                        className={classes.footerLink}
                    >
                        <span className={classes.footerLinkText}>Privacy Policy</span>
                    </Link>
                    <span className={classes.footerSeparator} />
                    <Link href={footerLinks.terms} underline="none" className={classes.footerLink}>
                        <span className={classes.footerLinkText}>Terms of Use</span>
                    </Link>
                    <span className={classes.footerSeparator} />
                    <Link href={footerLinks.ansto} underline="none" className={classes.footerLink}>
                        <span>
                            {'\u00A9'} {new Date().getFullYear()}{' '}
                        </span>
                        <span className={classes.footerLinkText}>ANSTO</span>
                    </Link>
                </Box>
            </Hidden>
        </>
    );
};

export default Menu;
