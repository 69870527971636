import { ProtectedRoute, SingleSignOnProvider } from 'as-sso-toolbox';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// STYLES
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import theme from './theme/theme';

// FUNCTIONS
import { env } from './config';

// PAGES
import Faq from './pages/faq';
import Main from './pages/main';
import NotFound from './pages/notfound';

export const App = () => (
    <SingleSignOnProvider
        ssoApiUrl={`${env.REACT_APP_SSO_UI_URL}`}
        clientId={`${env.REACT_APP_SSO_UI_CLIENT_ID}`}
    >
        <BrowserRouter>
            <div id="App" className="App">
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Switch>
                        <ProtectedRoute exact path="/" component={Main} />
                        <ProtectedRoute exact path="/faq" component={Faq} />
                        <Route component={NotFound} />
                    </Switch>
                </MuiThemeProvider>
            </div>
        </BrowserRouter>
    </SingleSignOnProvider>
);

export default App;
