import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// COMPONENTS
import MessageBox from './MessageBox';

// STYLES
import useAxiosErrorsStyles from '../../theme/elements/axiosErrors.style';

const AxiosErrors = (props) => {
    const { customClasses, errors, renderType } = props;
    const classes = useAxiosErrorsStyles();

    const renderErrors = () => {
        switch (renderType) {
            case 'form':
                return (
                    <div
                        className={cx(classes.root, customClasses)}
                        role="alert"
                        aria-live="assertive"
                    >
                        <h5>Errors</h5>
                        <div>
                            {errors.map((row) => (
                                <p key={row.toString()}>{row}</p>
                            ))}
                        </div>
                    </div>
                );
            case 'page':
                return (
                    <MessageBox
                        alert
                        customClasses={customClasses}
                        role="alert"
                        aria-live="assertive"
                    >
                        {errors.map((row) => (
                            <p key={row.toString()}>{row}</p>
                        ))}
                    </MessageBox>
                );
            default:
                return (
                    <>
                        {errors.map((row) => (
                            <p key={row.toString()} role="alert" aria-live="assertive">
                                {row}
                            </p>
                        ))}
                    </>
                );
        }
    };

    return renderErrors();
};

AxiosErrors.propTypes = {
    customClasses: PropTypes.string,
    errors: PropTypes.instanceOf(Array),
    renderType: PropTypes.string,
};

AxiosErrors.defaultProps = {
    customClasses: null,
    errors: [],
    renderType: 'page',
};

export default AxiosErrors;
