// Brand Globals
const brand = {
    palette: {
        white: '#fff',
        black: '#1a1a1a',
        text: '#484848',
        grey: '#58595b',
        flag: '#1a3869',
        blue: '#0076c0',
        seafoam: '#41c0c0',
        yellow: '#fcb13e',
        purple: '#6c4780',
        green: '#007a3d',
    },
    fontFamily: {
        firaSans: ['Fira Sans', 'Tahoma', 'sans-serif'].join(','),
        poppins: ['Poppins', 'Tahoma', 'sans-serif'].join(','),
    },
};

export default brand;
