import makeStyles from '@material-ui/core/styles/makeStyles';

const useAccountStyles = makeStyles((theme) => ({
    account: {
        [theme.breakpoints.down('sm')]: {
            opacity: 0,
            pointerEvents: 'none',
            visibility: 'hidden',
            '&.active': {
                opacity: 1,
                pointerEvents: 'all',
                visibility: 'visible',
            },
        },
    },
    name: {
        alignItems: 'center',
        display: 'flex',
    },
    avatar: {
        backgroundColor: theme.palette.background.avatar,
        borderRadius: theme.shape.borderCircle,
        display: 'inline-block',
        height: theme.spacing(4),
        marginLeft: theme.spacing(1.5),
        overflow: 'hidden',
        position: 'relative',
        width: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
        '& img': {
            height: theme.spacing(4),
            left: '50%',
            marginLeft: '-50%',
            marginTop: '-50%',
            objectFit: 'cover',
            position: 'absolute',
            top: '50%',
            width: theme.spacing(4),
        },
    },
    separator: {
        backgroundColor: theme.palette.white[60],
        display: 'block',
        height: theme.spacing(4),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 1,
        [theme.breakpoints.up('sm')]: {
            backgroundColor: theme.palette.grey[30],
        },
        '&.active': {
            backgroundColor: theme.palette.white[60],
        },
    },
    link: {
        ...theme.typography.link,
    },
}));

export default useAccountStyles;
