// Polyfills
import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// React
import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-named-as-default
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <>
        <App />
    </>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
