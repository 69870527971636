/* eslint-disable import/prefer-default-export */

/**
 * Calculate and format the datetime from UTC to AEST.
 *
 * @param {String} datetime UTC datetime
 * @return {String} Formatted datetime like `14/05/2023`
 */
export const formatDate = (datetime) => {
    if (!datetime) return false;
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'Australia/Melbourne',
    };
    return new Date(datetime).toLocaleString('en-AU', options);
};

/**
 * Refresh current page
 */
export const refreshPage = () => {
    window.location.reload(false);
};

/**
 * Compare 2 objects by key
 *
 * @param {object} a Object to compare
 * @param {object} b Object to compare
 * @param {string} sort Object key to sort by
 * @return {integer} -1 || 0 || 1 Whether object is < || = || >
 */
const descendingComparator = (a, b, sort) => {
    if (b[sort] < a[sort]) return -1;
    if (b[sort] > a[sort]) return 1;
    return 0;
};

/**
 * Create comparison sorting function of chosen object key to sort by,
 * and whether in ascending or descending order
 *
 * @param {string} order asc || desc To sort ascending or descending
 * @param {string} sort Object key to sort by
 * @return {function} Sorting function
 */
export const getComparator = (order, sort) =>
    order === 'desc'
        ? (a, b) => descendingComparator(a, b, sort)
        : (a, b) => -descendingComparator(a, b, sort);

/**
 * Sort array by custom comparison function
 *
 * @param {array} array Array of objects to sort
 * @param {function} comparator Comparison function
 * @return {array} Sorted array
 */
export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

/**
 * Set BFF error messages
 *
 * @param {object} error HTTPS exception error object from BFF
 * @param {string} custom Optional custom error message to display at top for further clarity
 * @return {array} Array of error messages to display to user
 */
export const formatBFFPostPutErrors = (error, custom) => {
    const errs = [];
    if (custom) errs.push(custom);
    if (error.response && error.response.data) {
        if (error.response.status === 422 && error.response.data.detail) {
            error.response.data.detail.forEach((e) => {
                errs.push(`${e}`);
            });
        } else if (error.response.data.detail) {
            errs.push(`${error.response.status} Error: ${error.response.data.detail}`);
        } else {
            errs.push(`${error.response.status} Error: ${error.response.data}`);
        }
    } else {
        errs.push(`${error}`);
    }
    return errs;
};
