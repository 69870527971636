import { useState, useEffect } from 'react';
import { useSingleSignOn } from 'as-sso-toolbox';
import axios from 'axios';

const useAxios = (baseURL) => {
    const [sso, initialized] = useSingleSignOn();
    const [axiosInstance, setAxiosInstance] = useState({});

    useEffect(() => {
        const instance = axios.create({
            baseURL,
            headers: {
                Authorization:
                    initialized && sso.authenticated ? `Bearer ${sso.token}` : undefined,
            },
        });

        setAxiosInstance({ instance });

        // return function that will be used for cleanup by React
        return () => {
            setAxiosInstance({});
        };
    }, [baseURL, initialized, sso, sso.token]);

    return axiosInstance.instance;
};

export default useAxios;
