import { alpha } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useNavStyles = makeStyles((theme) => ({
    overlay: {
        bottom: 0,
        display: 'flex',
        height: '100%',
        left: 0,
        opacity: 0,
        pointerEvents: 'none',
        position: 'fixed',
        right: 0,
        top: 0,
        transition: `opacity ${theme.transitions.duration.standard}ms ease-out`,
        visibility: 'hidden',
        width: '100%',
        zIndex: theme.zIndex.modal,
        '&.active': {
            opacity: 1,
            visibility: 'visible',
        },
    },
    overlayGrid: {
        height: '101%',
    },
    drawerWrap: {
        marginLeft: 'auto',
        opacity: 0,
        position: 'relative',
        transform: 'translateX(110%)',
        transition: `all ${theme.transitions.duration.complex}ms ease-in-out`,
        '&:before': {
            backgroundImage: `linear-gradient(top, ${theme.palette.gradient.medium} 0%, ${alpha(
                theme.palette.gradient.medium,
                1
            )} 50%, ${alpha(theme.palette.gradient.medium, 0)} 100%)`,
            top: 0,
            content: '""',
            display: 'block',
            height: theme.spacing(2) + theme.spacing(6) + 22, // toolbar padding + subtoolbar padding + subtoolbar lineheight
            left: theme.spacing(1),
            position: 'absolute',
            width: '100%',
            zIndex: 1,
            [theme.breakpoints.up('sm')]: {
                height: theme.spacing(4) + theme.spacing(8) + theme.spacing(2) + 24,
            },
            [theme.breakpoints.up('md')]: {
                height: theme.spacing(4) + theme.spacing(9) + theme.spacing(2) + 26,
            },
        },
        '&:after': {
            [theme.breakpoints.down('xs')]: {
                backgroundColor: theme.palette.gradient.medium,
                content: '""',
                display: 'block',
                height: '100%',
                left: theme.spacing(-1),
                position: 'absolute',
                top: 0,
                width: '100vw',
                zIndex: -1,
            },
        },
        '&.active': {
            opacity: 1,
            transform: 'translateX(0%)',
        },
    },
    drawer: {
        '-ms-overflow-style': 'none',
        backgroundColor: theme.palette.gradient.medium,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        left: theme.spacing(1),
        minHeight: '100%',
        overflowX: 'hidden',
        overflowY: 'scroll',
        paddingTop: theme.spacing(13), // header height
        pointerEvents: 'all',
        position: 'absolute',
        scrollbarWdth: 'none',
        top: 0,
        width: '100vw',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(17), // header height
            width: '300vw',
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(19), // header height
        },
    },
}));

export default useNavStyles;
