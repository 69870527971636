import makeStyles from '@material-ui/core/styles/makeStyles';

const useBreadcrumbsStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.meta,
        fontFamily: theme.typography.fontFamilyPoppins,
        marginBottom: theme.spacing(4),
        transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.short,
        }),
        '& a': {
            color: theme.palette.action.active,
        },
        '& a:hover': {
            color: theme.palette.action.hover,
        },
        '& a:focus': {
            color: theme.palette.action.focus,
        },
    },
    current: {
        color: theme.palette.action.disabled,
    },
}));

export default useBreadcrumbsStyles;
