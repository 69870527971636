import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// MUI COMPONENTS
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// COMPONENTS
import AxiosErrors from '../components/elements/AxiosErrors';
import Breadcrumbs from '../components/elements/Breadcrumbs';
import Faqs from '../components/faqs/Faqs';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import SideMenu from '../components/modules/SideMenu';

// FUNCTIONS
import useAxios from '../components/functions/useAxios';
import { formatBFFPostPutErrors } from '../components/functions/utils';
import { env } from '../config';

// STYLES
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        '& + &': {
            marginTop: theme.spacing(5),
        },
    },
    sidebar: {
        '&:first-child': {
            marginTop: theme.spacing(5),
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(2.75 + 4 + 5.25 + 2),
            },
        },
    },
}));

export default () => {
    const { hash } = useLocation();
    const classes = useStyles();
    const [errMsgs, setErrMsgs] = useState([]);
    const bffAxios = useAxios(`${env.REACT_APP_DMPUBLIC_BFF_URL}`);
    const [user, setUser] = useState({});

    const getUser = useCallback(async () => {
        if (bffAxios) {
            bffAxios
                .get(`/user/current`)
                .then((res) => {
                    setUser(res.data);
                })
                .catch((error) => {
                    const errs = formatBFFPostPutErrors(error);
                    setErrMsgs(errs);
                });
        }
    }, [bffAxios]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    return (
        <>
            <a
                href="#skip-to-main"
                className="skip-to-main-link"
                aria-label="skip to main content"
            >
                Skip to main content
            </a>
            <Header user={user} title="Data FAQs" />
            <Container id="skip-to-main">
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid className={classes.main} component="main" item md={8} xs={12}>
                        <Breadcrumbs
                            crumbs={[
                                {
                                    label: 'Dashboard',
                                    link: env.REACT_APP_DASHBOARD_USER_UI_URL,
                                },
                                {
                                    label: 'Data Status',
                                    link: '/',
                                },
                            ]}
                            current="FAQs"
                        />
                        {errMsgs.length > 0 && (
                            <AxiosErrors
                                errors={errMsgs}
                                renderType="page"
                                customClasses={classes.section}
                            />
                        )}
                        <Faqs hash={hash} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SideMenu customClasses={classes.sidebar} />
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
};
