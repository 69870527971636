import makeStyles from '@material-ui/core/styles/makeStyles';

const useSideMenuStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(7.25),
        },
    },
    toggle: {
        ...theme.props.buttonIconOnly,
        position: 'absolute',
        right: theme.spacing(2),
        top: `calc((${theme.spacing(6.25)}px - ${theme.spacing(4)}px) / 2)`,
        transform: 'rotate(90deg)',
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short,
        }),
        [theme.breakpoints.up('md')]: {
            display: 'none',
            visibility: 'hidden',
        },
    },
    toggleOpened: {
        '&$toggle': {
            transform: 'rotate(270deg)',
        },
    },
    toggleIcon: {
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    menu: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: theme.props.shadows.menu,
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            bottom: 0,
            left: 0,
            position: 'fixed',
            transform: `translateY(calc(100% - ${theme.spacing(6.25)}px))`,
            width: '100%',
            zIndex: theme.zIndex.mobileStepper,
            transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.short,
            }),
        },
    },
    menuOpened: {
        '&$menu': {
            [theme.breakpoints.down('sm')]: {
                transform: 'translateY(0)',
            },
        },
    },
    label: {
        ...theme.typography.menuLabel,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        margin: 0,
        padding: 16,
    },
    percentage: {
        background: `linear-gradient(left, ${theme.palette.secondary.main},  ${theme.palette.secondary.main} 45%,  ${theme.palette.primary.main} 55%, ${theme.palette.primary.main})`,
        backgroundPosition: '100%',
        backgroundSize: '220% 1px',
        transition: theme.transitions.create(['background', 'background-color'], {
            duration: theme.transitions.duration.complex,
            easing: theme.transitions.easing.easeIn,
        }),
    },
    list: {
        listStyle: 'none',
        margin: 0,
        padding: 16,
    },
    item: {
        '& + &': {
            marginTop: theme.spacing(3),
        },
    },
    link: {
        display: 'inline-block',
        '&.active > $span': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    span: {
        ...theme.typography.menuItem,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        display: 'inline-block',
        position: 'relative',
        textDecoration: 'none',
        transition: `color ${theme.transitions.duration.shorter}ms ease-out`,
        whiteSpace: 'nowrap',
        '&:after': {
            backgroundColor: theme.palette.secondary.main,
            bottom: -2,
            content: '""',
            display: 'block',
            height: 1,
            left: 0,
            position: 'absolute',
            transition: `width ${theme.transitions.duration.shorter}ms ease-out`,
            width: '0%',
        },
        '$link:not[.active]:hover > &, $link:not[.active]:focus > &': {
            color: theme.palette.secondary.main,
        },
        '$link:hover > &:after, $link:focus > &:after': {
            width: '100%',
        },
    },
}));

export default useSideMenuStyles;
