import makeStyles from '@material-ui/core/styles/makeStyles';

const useFaqsStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.gradient.dark,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(4),
        },
        'div + &': {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(4),
            },
        },
    },
}));

export default useFaqsStyles;
