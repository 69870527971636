import makeStyles from '@material-ui/core/styles/makeStyles';

const useDatasetTableStyles = makeStyles((theme) => ({
    buttonSort: {
        height: theme.spacing(4),
        marginRight: theme.spacing(3),
        width: theme.spacing(4),
        [theme.breakpoints.down(479)]: {
            marginBottom: theme.spacing(5),
        },
        '& .MuiSvgIcon-root': {
            height: theme.spacing(4),
            width: theme.spacing(4),
        },
    },
    tableContainer: {
        marginTop: theme.spacing(6),
        transition: `margin-top ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeIn}`,
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(8),
        },
    },
    row: {
        borderBottom: 'none',
    },
    cell: {
        borderBottom: 'none',
        color: theme.palette.text.secondary,
        lineHeight: 1,
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: 0,
        paddingTop: 0,
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
        },
    },
    body: {
        borderBottom: `1px solid ${theme.palette.background.light}`,
        boxShadow: `${theme.props.shadows.tableTop}, ${theme.props.shadows.tableBottom}`,
    },
    paginationButton: {
        ...theme.props.iconButton,
    },
}));

export default useDatasetTableStyles;
