import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgLogo = (props) => (
    <SvgIcon viewBox="0 0 124 102.659" {...props}>
        <path
            className="path-seafoam"
            fill="#40C0C0"
            d="M76.44.2C74.476 67.795 68.935 60.541 124 86.862L76.44.199z"
        />
        <path
            className="path-flag"
            fill="#1A3869"
            d="M1.733 102.659c41.362-8.715 92.63-12.91 120.39-12.91-57.752-24.486-45.38-25.66-120.39 12.91"
        />
        <path
            className="path-blue"
            fill="#0076C0"
            d="M72.933 0C68.057 69.36 75.66 57.792 0 99.538L72.933 0z"
        />
    </SvgIcon>
);

export default SvgLogo;
