import PropTypes from 'prop-types';
import React from 'react';
import { scroller } from 'react-scroll';
import slugify from 'slugify';

// MUI COMPONENTS
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// SVGS
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import LayersSharpIcon from '@material-ui/icons/LayersSharp';
import LockSharpIcon from '@material-ui/icons/LockSharp';

// COMPONENTS
import FaqPanel from './FaqPanel';

// STYLES
import useFaqsStyles from '../../theme/faqs/faqs.style';

const Faqs = (props) => {
    const { hash } = props;
    const classes = useFaqsStyles();

    const getFaq = (array, id) => {
        if (!array) return '';
        const faq = array.filter((i) => i.id === id);
        if (faq.length) return faq[0];
        return '';
    };

    const getFaqSlug = (section, id) => {
        if (!section || !id) return '';
        return slugify(getFaq(section, id).title, { lower: true, strict: true });
    };

    const understandingRecords = [
        {
            id: 1,
            title: 'What do the icons mean?',
            content: (
                <>
                    <p>
                        Icons in front of the EPN represent the current storage state of that data.
                    </p>
                    <p>
                        <LayersSharpIcon titleAccess="Data is compressed" color="secondary" /> (the
                        data has been compressed, it can be accessed but cannot be edited)
                    </p>
                    <p>
                        <LockSharpIcon titleAccess="Data is archived" color="secondary" /> (the
                        data has been archived and cannot be accessed)
                    </p>
                    <p>
                        <DeleteSharpIcon titleAccess="Data is deleted" color="secondary" /> (the
                        data has been deleted)
                    </p>
                    <p>
                        Otherwise, if there is no icon before the EPN, the data is unrestricted and
                        can still be edited. If you are still editing the data, please make sure to
                        download the data <strong>after</strong> your latest change.
                    </p>
                </>
            ),
        },
        {
            id: 2,
            title: 'Why is the data record highlighted in yellow?',
            content: (
                <>
                    <p>
                        Data expiring in 1 month or less are{' '}
                        <span className="background--expiring">highlighted in yellow</span>. Please
                        note the expiry date and download the data as soon as possible if you
                        require it.
                    </p>
                    <p>
                        If you are currently interstate or overseas. Please note that expiry dates
                        are according to <strong>AEST (Australian Eastern Standard Time)</strong>.
                    </p>
                </>
            ),
        },
        {
            id: 3,
            title: 'Why is the data record highlighted in red?',
            content: (
                <>
                    <p>
                        Expired data is{' '}
                        <span className="background--expired">highlighted in red</span>. Please
                        check with the Principal Investigator to see if they, or anyone else on the
                        team, have downloaded a version.{' '}
                        <strong>Expired data can be deleted at any time.</strong>
                    </p>
                </>
            ),
        },
        {
            id: 4,
            title: 'Why is the data record greyed out?',
            content: (
                <>
                    <p>
                        Deleted data is <span className="background--deleted">greyed out</span>{' '}
                        (also indicted by the{' '}
                        <DeleteSharpIcon titleAccess="Data is deleted" color="secondary" /> icon).
                        Please check with the Principal Investigator to see if they, or anyone else
                        on the team, have downloaded a version.
                    </p>
                </>
            ),
        },
    ];

    const gettingHelp = [
        {
            id: 1,
            title: 'How do I download the data?',
            content: (
                <>
                    <p>
                        You can find out information and instructions on how to download the data
                        at{' '}
                        <a href="https://asci.readthedocs.io/en/latest/data/downloading.html">
                            https://asci.readthedocs.io/en/latest/data/downloading.html
                        </a>
                    </p>
                </>
            ),
        },
        {
            id: 2,
            title: 'What should I do if the data has been compressed?',
            content: (
                <>
                    <p>
                        Compressed data can still be downloaded using our SFTP service, however it
                        cannot be edited or accessed via ASCI.
                    </p>
                    <p>
                        If you would like to access it on ASCI, please send your access request to{' '}
                        <strong>as-scientific-computing@ansto.gov.au</strong> with the following data record
                        information:
                    </p>
                    <ul>
                        <li>
                            <strong>Enquiry for Compressed Data</strong>
                        </li>
                        <li>EPN</li>
                        <li>Beamline</li>
                        <li>Reason for access</li>
                    </ul>
                </>
            ),
        },
        {
            id: 3,
            title: 'What should I do if the data has been archived?',
            content: (
                <>
                    <p>
                        Archived data has been moved to the archive system, it cannot be accessed
                        via ASCI or downloaded via SFTP.
                    </p>
                    <p>
                        If the data has been archived and you would like to access it, please send
                        your access request to <strong>as-scientific-computing@ansto.gov.au</strong> with the
                        following data record information:
                    </p>
                    <ul>
                        <li>
                            <strong>Enquiry for Archived Data</strong>
                        </li>
                        <li>EPN</li>
                        <li>Beamline</li>
                        <li>Required Access (SFTP/ASCI)</li>
                        <li>Reason for access</li>
                    </ul>
                </>
            ),
        },
        {
            id: 4,
            title: 'What should I do if the data has been deleted?',
            content: (
                <>
                    <p>
                        If the data has been deleted, please check with the Principal Investigator
                        to see if they, or anyone else on the team, have downloaded a version.
                    </p>
                </>
            ),
        },
        {
            id: 5,
            title: "I'm still having trouble accessing the data?",
            content: (
                <>
                    <p>
                        If you&apos;re still having trouble accessing the data, please send your
                        enquiry to <strong>as-scientific-computing@ansto.gov.au</strong> with the following
                        data record information:
                    </p>
                    <ul>
                        <li>
                            <strong>Enquiry for Data Access</strong>
                        </li>
                        <li>EPN</li>
                        <li>Beamline</li>
                        <li>
                            Storage State (as currently indicated by{' '}
                            <Link
                                underline="none"
                                // eslint-disable-next-line no-use-before-define
                                onClick={(e) => goToAnchor(e, getFaqSlug(understandingRecords, 1))}
                                href={`#${getFaqSlug(understandingRecords, 1)}`}
                                aria-label={`Scroll to "${getFaq(understandingRecords, 1).title}"`}
                            >
                                the icon
                            </Link>
                            )
                        </li>
                        <li>Principal Investigator</li>
                        <li>Reason for enquiry</li>
                    </ul>
                </>
            ),
        },
    ];

    const requestingExtension = [
        {
            id: 1,
            title: 'Can I get an extension on expiring data?',
            content: (
                <>
                    <p>
                        Extensions are available for valid reasons (e.g. on-going analysis on ASCI,
                        finalising publication or thesis, no access to a stable WiFi connection).
                    </p>
                    <p>
                        Please send your extension request to{' '}
                        <strong>as-scientific-computing@ansto.gov.au</strong> with the following data record
                        information:
                    </p>
                    <ul>
                        <li>
                            <strong>Enquiry for Data Extension</strong>
                        </li>
                        <li>EPN</li>
                        <li>Beamline</li>
                        <li>Principal Investigator</li>
                        <li>Reason for extension</li>
                    </ul>
                </>
            ),
        },
    ];

    const faqData = {
        understandingRecords,
        gettingHelp,
        requestingExtension,
    };

    const [expanded, setExpanded] = React.useState([
        getFaqSlug(understandingRecords, 1),
        getFaqSlug(gettingHelp, 1),
        getFaqSlug(requestingExtension, 1),
    ]);

    const collapsePanel = (slug) => {
        if (expanded.includes(slug)) {
            const array = expanded.filter((i) => i !== slug);
            setExpanded(array);
        }
    };

    const expandPanel = (slug) => {
        if (!expanded.includes(slug)) {
            const array = [...expanded, slug];
            setExpanded(array);
        }
    };

    const handleRequestExpanded = (e, slug) => {
        if (expanded.includes(slug)) {
            collapsePanel(slug);
        } else {
            expandPanel(slug);
        }
    };

    const goToAnchor = (e, anchor) => {
        if (e) e.preventDefault();
        if (anchor) {
            expandPanel(anchor.replace('#', ''));
            scroller.scrollTo(anchor.replace('#', ''), {
                smooth: true,
                duration: 400,
            });
        }
    };

    React.useEffect(() => {
        if (hash) {
            goToAnchor(null, hash);
        }
    }, [hash]);

    return (
        <>
            <Typography variant="h3" className={classes.title}>
                Understanding the records
            </Typography>
            {faqData.understandingRecords.map((data) => (
                <FaqPanel
                    key={slugify(data.title, { lower: true, strict: true })}
                    slug={slugify(data.title, { lower: true, strict: true })}
                    title={data.title}
                    content={data.content}
                    onRequestExpanded={handleRequestExpanded}
                    expanded={expanded.includes(
                        slugify(data.title, { lower: true, strict: true })
                    )}
                />
            ))}

            <Typography variant="h3" className={classes.title}>
                Getting Help
            </Typography>
            {faqData.gettingHelp.map((data) => (
                <FaqPanel
                    key={slugify(data.title, { lower: true, strict: true })}
                    slug={slugify(data.title, { lower: true, strict: true })}
                    title={data.title}
                    content={data.content}
                    onRequestExpanded={handleRequestExpanded}
                    expanded={expanded.includes(
                        slugify(data.title, { lower: true, strict: true })
                    )}
                />
            ))}

            <Typography variant="h3" className={classes.title}>
                Requesting an Extension
            </Typography>
            {faqData.requestingExtension.map((data) => (
                <FaqPanel
                    key={slugify(data.title, { lower: true, strict: true })}
                    slug={slugify(data.title, { lower: true, strict: true })}
                    title={data.title}
                    content={data.content}
                    onRequestExpanded={handleRequestExpanded}
                    expanded={expanded.includes(
                        slugify(data.title, { lower: true, strict: true })
                    )}
                />
            ))}
        </>
    );
};

Faqs.propTypes = {
    hash: PropTypes.string,
};

Faqs.defaultProps = {
    hash: null,
};

export default Faqs;
