import makeStyles from '@material-ui/core/styles/makeStyles';

const useMenuStyles = makeStyles((theme) => ({
    section: {
        display: 'flex',
        flexDirection: 'column',
        listStyleType: 'none',
        margin: `0 0 ${theme.spacing(2)}px 0`,
        padding: 0,
    },
    label: {
        ...theme.typography.navLabel,
        color: theme.palette.white[60],
        marginBottom: theme.spacing(1),
    },
    link: {
        ...theme.typography.navItem,
        display: 'inline-block',
        color: theme.palette.common.white,
        marginBottom: theme.spacing(1),
        position: 'relative',
        '&:after': {
            backgroundColor: theme.palette.common.white,
            bottom: -2,
            content: '""',
            display: 'block',
            height: 1,
            left: 0,
            position: 'absolute',
            transition: `width ${theme.transitions.duration.shorter}ms ease-out`,
            width: '0%',
        },
        '&:hover:after': {
            width: '100%',
        },
        '&:focus:after': {
            width: '100%',
        },
    },
    footer: {
        listStyleType: 'none',
        padding: 0,
    },
    footerLink: {
        ...theme.typography.footer,
        color: theme.palette.common.white,
        '&:hover $footerLinkText': {
            color: theme.palette.common.white,
        },
        '&:hover $footerLinkText:after': {
            width: '100%',
        },
        '&:focus $footerLinkText': {
            color: theme.palette.common.white,
        },
        '&:focus $footerLinkText:after': {
            width: '100%',
        },
    },
    footerLinkText: {
        position: 'relative',
        transition: `color ${theme.transitions.duration.shorter}ms ease-out`,
        '&:after': {
            backgroundColor: theme.palette.common.white,
            bottom: -2,
            content: '""',
            display: 'block',
            height: 1,
            left: 0,
            position: 'absolute',
            transition: `width ${theme.transitions.duration.shorter}ms ease-out`,
            width: '0%',
        },
    },
    footerSeparator: {
        backgroundColor: theme.palette.white[60],
        display: 'block',
        height: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 1,
    },
}));

export default useMenuStyles;
