import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// STYLES
import useBreadcrumbsStyles from '../../theme/elements/breadcrumbs.style';

const Breadcrumbs = (props) => {
    const { customClasses, crumbs, current } = props;
    const classes = useBreadcrumbsStyles();

    return (
        <>
            <MuiBreadcrumbs
                className={cx(classes.root, customClasses)}
                separator={<NavigateNextIcon color="disabled" fontSize="small" />}
                aria-label="breadcrumb"
            >
                {crumbs.map((c) => (
                    <Link key={c.label.toString()} color="primary" href={c.link}>
                        {c.label}
                    </Link>
                ))}
                <span className={classes.current}>{current}</span>
            </MuiBreadcrumbs>
        </>
    );
};

Breadcrumbs.propTypes = {
    crumbs: PropTypes.instanceOf(Array),
    current: PropTypes.string,
    customClasses: PropTypes.string,
};

Breadcrumbs.defaultProps = {
    crumbs: [],
    current: '',
    customClasses: '',
};

export default Breadcrumbs;
