import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

// SVGS
import SearchSharpIcon from '@material-ui/icons/SearchSharp';

// COMPONENTS
import ButtonIcon from '../elements/ButtonIcon';

// STYLES
import useDatasetSearchStyles from '../../theme/main/datasetSearch.style';

const DatasetSearch = (props) => {
    const { onRequestSearch, total } = props;
    const classes = useDatasetSearchStyles();
    const [currentInput, setCurrentInput] = React.useState('');
    const [searchInput, setSearchInput] = React.useState('');

    const debounce = (func, delay = 250) => {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const userInput = (value) => {
        setCurrentInput(value);
    };

    const debouncedUserInput = debounce(userInput);

    const createSearchHandler = () => {
        if (
            currentInput.trim() !== searchInput &&
            (currentInput.trim().length > 2 || currentInput.trim().length < 1)
        ) {
            setSearchInput(currentInput.trim());
            onRequestSearch(currentInput.trim());
        }
    };

    return (
        <Grid container justifyContent="flex-end">
            <Grid item xs={12} className={classes.searchGrid}>
                <div className={classes.searchWrapper}>
                    <Input
                        id="input-search"
                        onChange={(e) => debouncedUserInput(e.target.value)}
                        placeholder="Search for record"
                        classes={{
                            root: classes.searchField,
                            input: classes.searchInput,
                        }}
                        inputProps={{
                            'aria-label': 'search data records',
                        }}
                        endAdornment={
                            <InputAdornment position="start">
                                <ButtonIcon
                                    aria-label="start typing to search records"
                                    title="start typing to search records"
                                    onClick={createSearchHandler()}
                                >
                                    <SearchSharpIcon role="presentation" />
                                </ButtonIcon>
                            </InputAdornment>
                        }
                    />
                    <Typography
                        component="p"
                        className={classes.total}
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <strong>{total}</strong> records found
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
};

DatasetSearch.propTypes = {
    onRequestSearch: PropTypes.func,
    total: PropTypes.number,
};

DatasetSearch.defaultProps = {
    onRequestSearch: () => null,
    total: null,
};

export default DatasetSearch;
