import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// STYLES
import useDatasetSortStyles from '../../theme/main/datasetSort.style';

const DatasetSort = (props) => {
    const { onRequestSort, onRequestDisplayed } = props;
    const [displayed, setDisplayed] = React.useState(true);
    const classes = useDatasetSortStyles();

    const createSortHandler = (value) => {
        onRequestSort(value);
    };

    const handleChangeDisplayed = (value) => {
        setDisplayed(value);
        onRequestDisplayed(value);
    };

    return (
        <div className={classes.container}>
            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={6} sm={4}>
                    <div>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend" className={classes.formLabel}>
                                Sort by
                            </FormLabel>
                            <RadioGroup
                                aria-label="Sort by"
                                name="sort-by"
                                defaultValue="epn"
                                onChange={(e) => createSortHandler(e.target.value)}
                            >
                                <FormControlLabel
                                    label="EPN"
                                    labelPlacement="start"
                                    value="epn"
                                    className={classes.formControlLabel}
                                    control={<Radio className={classes.radio} disableRipple />}
                                />
                                <FormControlLabel
                                    label="Beamline"
                                    labelPlacement="start"
                                    value="beamline"
                                    className={classes.formControlLabel}
                                    control={<Radio className={classes.radio} disableRipple />}
                                />
                                <FormControlLabel
                                    label="Title"
                                    labelPlacement="start"
                                    value="title"
                                    className={classes.formControlLabel}
                                    control={<Radio className={classes.radio} disableRipple />}
                                />
                                <FormControlLabel
                                    label="Size"
                                    labelPlacement="start"
                                    value="size"
                                    className={classes.formControlLabel}
                                    control={<Radio className={classes.radio} disableRipple />}
                                />
                                <FormControlLabel
                                    label="Expiry Date"
                                    labelPlacement="start"
                                    value="expires_on"
                                    className={classes.formControlLabel}
                                    control={<Radio className={classes.radio} disableRipple />}
                                />
                                <FormControlLabel
                                    label="Storage State"
                                    labelPlacement="start"
                                    value="storage_state"
                                    className={classes.formControlLabel}
                                    control={<Radio className={classes.radio} disableRipple />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div className={classes.columnRight}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend" className={classes.formLabel}>
                                Order by
                            </FormLabel>
                            <RadioGroup
                                aria-label="Order by"
                                name="order-by"
                                defaultValue="asc"
                                onChange={(e) => createSortHandler(e.target.value)}
                            >
                                <FormControlLabel
                                    label="Ascending"
                                    labelPlacement="start"
                                    value="asc"
                                    className={classes.formControlLabel}
                                    control={<Radio className={classes.radio} disableRipple />}
                                />
                                <FormControlLabel
                                    label="Descending"
                                    labelPlacement="start"
                                    value="desc"
                                    className={classes.formControlLabel}
                                    control={<Radio className={classes.radio} disableRipple />}
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormControlLabel
                                className={cx(classes.formControlLabel, classes.formCheckboxLabel)}
                                label="Display deleted data"
                                labelPlacement="start"
                                control={
                                    <Checkbox
                                        className={classes.radio}
                                        disableRipple
                                        checked={displayed}
                                        onChange={(e) => handleChangeDisplayed(e.target.checked)}
                                    />
                                }
                            />
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

DatasetSort.propTypes = {
    onRequestDisplayed: PropTypes.func,
    onRequestSort: PropTypes.func,
};

DatasetSort.defaultProps = {
    onRequestDisplayed: () => null,
    onRequestSort: () => null,
};

export default DatasetSort;
