import makeStyles from '@material-ui/core/styles/makeStyles';

const useDatasetSearchStyles = makeStyles((theme) => ({
    searchGrid: {
        transition: `max-width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
    },
    searchWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
    },
    searchField: {
        border: `1px solid ${theme.palette.action.active}`,
        borderRadius: theme.shape.borderRadius,
        transition: `border-color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeIn}`,
        width: '100%',
        '&::before': {
            content: 'none',
        },
        '&::after': {
            content: 'none',
        },
        '&:focus': {
            borderColor: theme.palette.action.focus,
        },
        '&:hover': {
            borderColor: theme.palette.action.hover,
        },
    },
    searchInput: {
        ...theme.typography.body1,
        height: theme.spacing(2),
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        [theme.breakpoints.up('md')]: {
            height: theme.spacing(3),
        },
    },
    total: {
        ...theme.typography.headerSub,
        bottom: `calc(-${theme.typography.pxToRem(14)} - ${theme.spacing(1)}px)`,
        color: theme.palette.text.secondary,
        display: 'block',
        fontFamily: theme.typography.fontFamilyPoppins,
        position: 'absolute',
        right: 0,
        [theme.breakpoints.up('md')]: {
            bottom: `calc(-${theme.typography.pxToRem(16)} - ${theme.spacing(1)}px)`,
        },
        '& strong': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
}));

export default useDatasetSearchStyles;
